import Splitting from "splitting";

import { $$, body } from "@utils/dom";


const splitByWordsForMaskAnimation = (el, options, ctx) => {
  const { words } = ctx;
  words.forEach(word => word.innerHTML = `<span class="wordText">${word.innerHTML}</span>`);

  return [];
};

const splitByCharsForButtonGlitchEffectTypewriting = (el, options, ctx) => {
  const { chars } = ctx;
  chars.forEach(char => char.innerHTML = `<span class="charTypeWriter">${char.innerHTML}</span>`);

  return [];
};

// split by words and wrap each word content into another span to perform masking animation
Splitting.add({
  by: 'wordsMask',
  key: 'wordsMask',
  depends: ['words'],
  split: splitByWordsForMaskAnimation,
});

// split by chars and wrap each char content into another span to perform typewriting animation
Splitting.add({
  by: 'glitchEffectTypewriting',
  key: 'glitchEffectTypewriting',
  depends: ['chars'],
  split: splitByCharsForButtonGlitchEffectTypewriting,
});


export default (el = body) => {
  [ ...$$('[data-splitting]', el) ].forEach(el => {
    const splittingMethod = el.dataset.splitting || "wordsMask";

    Splitting({
      target: el,
      by: splittingMethod
    });
  });
}
