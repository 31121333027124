/**
 * If you want to disable click SFX on a element, add [data-sfx-prevent] attribute.
 */
import anime from "animejs";
import EventEmitter2 from "eventemitter2";

import MILL3_EMITTER from "@core/emitter";
import windmill from "@core/windmill";
import { $, body } from "@utils/dom";
import { on } from "@utils/listener";

const CLICK_VOLUME = 0.1;
const ROLLOVER_SELECTOR = 'a, button';
const SOUNDTRACK_VOLUME = 1;
const SOUNDTRACK_FADE_IN_DURATION = 3000;
const SOUNDTRACK_FADE_OUT_DURATION = 2000;

class SFX extends EventEmitter2 {
  constructor() {
    super();

    this.target = null;
    this.click = $('#sfx-click');
    this.rollover = $('#sfx-rollover');
    this.menuOpen = $('#sfx-menu-open');
    this.menuClose = $('#sfx-menu-close');
    this.soundtrack = $('#sfx-soundtrack');

    if( this.click ) this.click.volume = CLICK_VOLUME;
    if( this.soundtrack ) this.soundtrack.volume = SOUNDTRACK_VOLUME;

    this._interacted = false;
    this._muted = true;//localStorage.getItem('sfx') == 0;
    this._playing = false;
    this._ready = false;
    this._volume = { volume: SOUNDTRACK_VOLUME };
    this._siteVideoOpened = false;

    this._onClick = this._onClick.bind(this);
    this._onRollover = this._onRollover.bind(this);
    this._onReady = this._onReady.bind(this);
    this._onFadeUpdate = this._onFadeUpdate.bind(this);
    this._onFadeOutCompleted = this._onFadeOutCompleted.bind(this);
    this._onSiteVideoPlay = this._onSiteVideoPlay.bind(this);
    this._onSiteVideoClosed = this._onSiteVideoClosed.bind(this);
  }

  init(target = body) {
    if( !this._muted ) {
      if( this.click ) this.click.setAttribute('preload', 'auto');
      if( this.rollover ) this.rollover.setAttribute('preload', 'auto');
      if( this.menuOpen ) this.menuOpen.setAttribute('preload', 'auto');
      if( this.menuClose ) this.menuClose.setAttribute('preload', 'auto');
      if( this.soundtrack ) this.soundtrack.setAttribute('preload', 'auto');
    }

    windmill.once('done', this._onReady);
    on(target, 'pointerdown', this._onClick);

    MILL3_EMITTER.on('SiteVideo.play', this._onSiteVideoPlay);
    MILL3_EMITTER.on('SiteVideo.oembed', this._onSiteVideoPlay);
    MILL3_EMITTER.on('SiteVideo.closed', this._onSiteVideoClosed);
    
    if( this.rollover ) on(ROLLOVER_SELECTOR, 'mouseenter', this._onRollover);
  }
  toggle() {
    this.muted = !this._muted;
  }
  menu(open = true) {
    if( this._muted || !this._interacted ) return;

    if( open ) this.menuOpen?.play();
    else this.menuClose?.play();
  }

  _startSoundtrack(fadeIn = false) {
    if( !this.soundtrack ) return;

    if( fadeIn ) {
      anime({
        targets: this._volume,
        volume: SOUNDTRACK_VOLUME,
        duration: SOUNDTRACK_FADE_IN_DURATION,
        easing: 'linear',
        begin: this._onFadeUpdate,
        update: this._onFadeUpdate,
      });
    }

    if( !this._playing ) this.soundtrack.play();
    this._playing = true;
  }
  _stopSoundtrack(fadeOut = false) {
    if( !this.soundtrack ) return;

    if( fadeOut ) {
      anime({
        targets: this._volume,
        volume: 0,
        duration: SOUNDTRACK_FADE_OUT_DURATION,
        easing: 'linear',
        update: this._onFadeUpdate,
        complete: this._onFadeOutCompleted,
      });
    }
    else this._onFadeOutCompleted();
  }

  _onClick(event) {
    if( this._muted ) return;

    this._interacted = true;

    // avoid sfx on element with [data-sfx-prevent]
    if( event && event.target.hasAttribute('data-sfx-prevent')) return;

    if( this.click ) this.click.play();
    if( this._ready ) this._startSoundtrack();
  }
  _onRollover() {
    if( this._muted || !this._interacted ) return;
    if( this.rollover ) this.rollover.play();
  }
  _onReady() {
    this._ready = true;
    if( this._muted || !this.soundtrack || !this._interacted ) return;

    this._startSoundtrack();
  }
  _onFadeUpdate() {
    if( this.soundtrack ) this.soundtrack.volume = this._volume.volume;
  }
  _onFadeOutCompleted() {
    if( this.soundtrack ) this.soundtrack.pause();
    this._playing = false;
  }
  _onSiteVideoPlay() {
    if( this._muted || this._siteVideoOpened ) return;
    this._siteVideoOpened = true;

    this._stopSoundtrack(true);
  }
  _onSiteVideoClosed() {
    if( this._muted || !this._siteVideoOpened) return;

    this._startSoundtrack(true);
    this._siteVideoOpened = false;
  }



  // getter - setter
  get muted() { return this._muted; }
  set muted(value) {
    if( value === this._muted ) return;

    this._muted = value;
    this.emit('change');

    //localStorage.setItem('sfx', this._muted ? 0 : 1);

    if( this._ready && this._interacted && this.soundtrack ) {
      if( this._muted ) this._stopSoundtrack(true);
      else this._startSoundtrack(true);
    }
  }
}

export default new SFX();
