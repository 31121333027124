import Splitting from "splitting";

import { shuffle } from "@utils/array";
import { $$ } from "@utils/dom";
import { on, off } from "@utils/listener";
import { mobile } from "@utils/mobile";

const MAX_CHARS = 7;

class ButtonGlitchEffect {
  constructor(el) {
    const splittingMethod = el.hasAttribute('data-button-glitch-effect-typewriting') ? 'glitchEffectTypewriting' : 'chars';

    this.el = el;
    this.labels = (this.el.dataset.buttonGlitchEffect ? [ ...$$(this.el.dataset.buttonGlitchEffect, this.el) ] : [this.el]).map(label => new ButtonLabel(label, splittingMethod));

    this._hover = false;
    this._index = 0;
    this._length = Math.max( ...this.labels.map(label => label.length) );
    this._tick = null;

    this._onMouseEnter = this._onMouseEnter.bind(this);
    this._onMouseLeave = this._onMouseLeave.bind(this);
    this._onInterval = this._onInterval.bind(this);

    this.init();
  }

  init() {
    this._bindEvents();
  }
  destroy() {
    this._unbindEvents();

    if( this._hover ) this._onMouseLeave();
    if( this.labels ) this.labels.forEach(label => label.destroy());

    this.el = null;
    this.labels = null;

    this._hover = null;
    this._index = null;
    this._length = null;
    this._tick = null;

    this._onMouseEnter = null;
    this._onMouseLeave = null;
    this._onInterval = null;
  }

  _bindEvents() {
    if( mobile ) return;

    on(this.el, 'mouseenter', this._onMouseEnter);
    on(this.el, 'mouseleave', this._onMouseLeave);
  }
  _unbindEvents() {
    if( mobile ) return;
    
    off(this.el, 'mouseenter', this._onMouseEnter);
    off(this.el, 'mouseleave', this._onMouseLeave);
  }

  _onMouseEnter() {
    this._hover = true;
    this._index = 0;

    // start all labels
    this.labels.forEach(label => label.start());

    // update label repeatedly
    if( !this._tick ) this._tick = setInterval(this._onInterval, 100);
  }
  _onMouseLeave() {
    this._hover = false;

    if( this._tick ) clearInterval(this._tick);
    this._tick = null;

    // stop all labels
    this.labels.forEach(label => label.stop());
  }
  _onInterval() {
    // update labels
    this.labels.forEach(label => label.update());

    // increase index
    this._index++;

    // check if longest label is finished
    if( this._index > this._length ) this._onMouseLeave();
  }
}

class ButtonLabel {
  constructor(el, splittingMethod = 'chars') {
    this.el = el;

    this._chars = Splitting({ target: this.el, by: splittingMethod })[0].chars;
    this._index = 0;
    this._length = Math.min(this._chars.length, MAX_CHARS);
  }

  destroy() {
    this.el = null;

    this._chars = null;
    this._index = null;
    this._length = null;
  }

  start() {
    this._index = 0;
    this._chars = shuffle(this._chars);
  }
  stop() {
    // this._chars.forEach(char => char.style.removeProperty('visibility'));
    this._chars.forEach(char => char.classList.remove('--glitching'));
  }
  update() {
    // show previous character
    // if( this._index > 0 && this._index <= this._length ) this._chars[ this._index - 1 ].style.removeProperty('visibility');
    if( this._index > 0 && this._index <= this._length ) this._chars[ this._index - 1 ].classList.remove('--glitching');

    // stop when all characters have been animated
    if( this._index >= this._length ) {
      this._index++;
      return;
    }

    // hide current character
    // this._chars[this._index].style.visibility = 'hidden';
    this._chars[this._index].classList.add('--glitching');

    // go to next character
    this._index++;
  }

  // getter 
  get length() { return this._length; }
}

export default ButtonGlitchEffect;
