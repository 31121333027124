import SiteTransition from "./SiteTransition";
import { moduleDelays } from "./utils";

import PowerMode from "@core/power-mode";
import { $ } from "@utils/dom";
import { on, off } from "@utils/listener";
import Viewport from "@utils/viewport";

class VideoTransition extends SiteTransition {
  constructor() {
    super();

    this.videoTransition = $('.video-transition');
    this.video = $('.video-transition__video', this.videoTransition);

    this._active = false;
    this._exitPromise = null;

    this._onVideoEnded = this._onVideoEnded.bind(this);
  }

  toString() { return "VideoTransition"; }

  exit(data) {
    this._active = false;

    const src = data.next.trigger.dataset.videoTransition;
    const src_mobile = data.next.trigger.dataset.videoTransitionMobile;

    if( PowerMode.low || (!src && !src_mobile) ) return super.exit(data);

    this._active = true;

    // show transition panel
    this.videoTransition.setAttribute('aria-hidden', false);

    off(this.video, 'ended', this._onVideoEnded);
    on(this.video, 'ended', this._onVideoEnded);

    return new Promise(resolve => {
      this._exitPromise = resolve;

      // set video source depending on viewport width
      if( Viewport.width < 768 ) this.video.src = src_mobile ? src_mobile : src;
      else this.video.src = src ? src : src_mobile;
    });
  }
  entering() { moduleDelays(350, this._active ? 0 : 150); }
  enter(data) {
    if( !this._active ) return super.enter(data);

    this.videoTransition.setAttribute('aria-hidden', true);
    this.video.src = "";
  }

  _onVideoEnded() {
    off(this.video, 'ended', this._onVideoEnded);

    if( this._active ) this._exitPromise();
    this._exitPromise = null;
  }
}

export default VideoTransition;
