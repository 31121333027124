import anime from "animejs";

import SiteTransition from "./SiteTransition";
import MILL3_EMITTER from "@core/emitter";
import PowerMode from "@core/power-mode";
import { $, rect } from "@utils/dom";
import Viewport from "@utils/viewport";

class GameHeaderTransition extends SiteTransition {
  constructor() {
    super();

    this.gameHeader = $('.game-header-transition');
    this.gameHeaderBg = $('.game-header-transition__bg', this.gameHeader);

    this._active = false;
    this._dummy = { y: 0, height: 0, opacity: 0 };
    this._target = null;
  }
  
  toString() { return "GameHeaderTransition"; }

  exit(data) {
    this._active = false;
    this._target = null;

    const selector = data.next.trigger.dataset.gameHeaderTransitionTarget;
    if( !selector || PowerMode.low ) return super.exit(data);

    const target = $(selector);
    if( !target ) return super.exit(data);

    this._active = true;
    this._target = target;

    const bcr = rect(this._target);
    let destinationHeight;

    if( Viewport.width < 768 ) destinationHeight = Viewport.width / 0.75;
    else if( Viewport.width < 1200 ) destinationHeight = Viewport.width / 1.40651801;
    else destinationHeight = Viewport.height;

    // show game-header-transition & inject video element into himself
    this.gameHeader.style.setProperty('--target-y', `${bcr.y}px`);
    this.gameHeader.style.setProperty('--target-height', `${bcr.height}px`);
    this.gameHeader.style.setProperty('--gradient-opacity', 0);
    this.gameHeader.setAttribute('aria-hidden', false);
    this.gameHeader.appendChild(this._target);

    // disable windmill's container
    data.current.container.classList.add('pointer-events-none');

    return new Promise(resolve => {
      anime({
        targets: this._dummy,
        y: [bcr.y, 0],
        height: [bcr.height, destinationHeight],
        opacity: {
          value: [0, 1],
          duration: 450,
          easing: 'linear',
        },
        duration: 950,
        easing: 'easeInOutQuart',
        update: () => {
          this.gameHeader.style.setProperty('--target-y', `${this._dummy.y}px`);
          this.gameHeader.style.setProperty('--target-height', `${this._dummy.height}px`);
          this.gameHeader.style.setProperty('--gradient-opacity', this._dummy.opacity);
        },
      });

      // fade-out windmill's container
      anime({
        targets: data.current.container,
        opacity: 0,
        duration: 650,
        easing: 'linear',
        complete: () => {
          // set game-header-transition on top of everything to make sure next page won't appear before "enter" transition
          this.gameHeader.classList.add('z-10000');
          resolve();
        },
      });
    });
  }

  enter(data) {
    if( !this._active ) return super.enter(data);

    // find transition destination
    const destination = $('video[data-game-header-transition-destination]', data.next.container);
    const parent = destination ? destination.parentElement : null;

    // remove destination from DOM and application memory
    if( destination ) {
      MILL3_EMITTER.emit('Video.destroy', destination);
      destination.remove();
    }

    // inject target into destination or remove from DOM if destination doesn't exists
    if( parent ) parent.appendChild(this._target);
    else this._target.remove();

    // hide game-header-transition
    this.gameHeader.setAttribute('aria-hidden', true);
    this.gameHeader.classList.remove('z-10000');

    // reset state
    this._target = null;
    this._active = false;
  }
}

export default GameHeaderTransition;
