import ButtonGlitchEffect from "@components/ButtonGlitchEffect";
import Video from "@components/Video";
import AgeGate from "@core/age-gate";
import PowerMode from "@core/power-mode";
import SFX from "@core/sfx";
import { $, html } from "@utils/dom";
import { requestInterval } from "@utils/interval";
import { on, off } from "@utils/listener";
import { mobile } from "@utils/mobile";
import ResizeOrientation from "@utils/resize";
import { moduleDelays } from "./utils";

const SELECTOR = "[data-site-loader]";
const AGE_GATE_CLASSNAME = "age-gate";
const ANIMATE_IN_CLASSNAME = "--animate-in";
const ANIMATE_OUT_CLASSNAME = "--animate-out";
const INTRO_CLASSNAME = "--intro";
const REQUIRED_AGE = 17;

class SiteLoader {
  constructor() {
    this.el = $(SELECTOR);
    this.bg = $('.site-loader__bg');
    this.intro = $('.site-loader__intro', this.el);
    this.bgVideo = $('.site-loader__bgVideo', this.el);
    this.introVideo = $('.site-loader__introVideo', this.el);
    this.form = $('.site-loader__form', this.el);
    this.btn = $('.site-loader__submit', this.el);
    this.day = $('select[name="ageGateDay"]', this.el);
    this.month = $('select[name="ageGateMonth"]', this.el);
    this.year = $('select[name="ageGateYear"]', this.el);
    this.audioBtn = $('.site-loader__audioBtn', this.el);
    this.sorry = $('.site-loader__sorry', this.el);

    this._audioBtn = null;
    this._ageGate = false;
    this._ageIsValid = true;
    this._readyPromise = null;
    this._tick = null;
    this._timecode = this.intro ? this.intro.dataset.timecode : null;

    this._onSubmit = this._onSubmit.bind(this);
    this._onAudioBtnClick = this._onAudioBtnClick.bind(this);
    this._onSFXChange = this._onSFXChange.bind(this);
    this._onAnimateOutCompleted = this._onAnimateOutCompleted.bind(this);
    this._onGoToIntroCompleted = this._onGoToIntroCompleted.bind(this);
    this._onIntroTimecode = this._onIntroTimecode.bind(this);
    this._onIntroEnded = this._onIntroEnded.bind(this);
  }

  init() {
    // if ageGate hasn't been approved before, show ageGate form
    if( AgeGate.locked ) {
      html.classList.add(AGE_GATE_CLASSNAME);

      this._ageGate = true;
      this._ageIsValid = false;

      if( this.btn ) this.btn = new ButtonGlitchEffect(this.btn);
      if( this.bgVideo ) this.bgVideo = new Video(this.bgVideo);

      if( this.introVideo ) {
        if( mobile ) this.introVideo.muted = true;
        else this.introVideo.volume = 0.5;

        this.introVideo.setAttribute('preload', 'metadata');
        this.introVideo = new Video(this.introVideo);
      }

      if( this.audioBtn && !mobile ) {
        // enable SFX by default
        SFX.muted = false;

        this._audioBtn = new ButtonGlitchEffect(this.audioBtn);
        this._onSFXChange();
      }

      this._bindEvents();
      this._animateIn();
    } else {
      this.btn = null;
      this.bgVideo = null;
      this.introVideo = null;
    }
  }
  loaded() {
    moduleDelays(350, 150);
  }
  ready() {
    return new Promise((resolve) => {
      this._readyPromise = resolve;

      // if ageGate is validated, go to next step
      if( this._ageIsValid ) {
        // if ageGate wasn't required, animateOut immediately
        if( !this._ageGate ) this._animateOut();
        else this._goToIntro();
      }
    });
  }

  _bindEvents() {
    if( this.form ) on(this.form, 'submit', this._onSubmit);
    if( this.audioBtn ) {
      SFX.on('change', this._onSFXChange);
      on(this.audioBtn, 'click', this._onAudioBtnClick);
    }
  }
  _unbindEvents() {
    if( this.el ) off(this.el, 'transitionend', this._onAnimateOutCompleted);

    if( this.introVideo ) {
      off(this.introVideo.el, 'ended', this._onIntroTimecode);
      off(this.introVideo.el, 'ended', this._onIntroEnded);
    }

    if( this.form ) {
      off(this.form, 'submit', this._onSubmit);
      off(this.form, 'animationend', this._onGoToIntroCompleted);
    }

    if( this.audioBtn ) {
      SFX.off('change', this._onSFXChange);
      off(this.audioBtn, 'click', this._onAudioBtnClick);
      off(this.audioBtn, 'animationend', this._onGoToIntroCompleted);
    }
  }
  _destroy() {
    this._unbindEvents();

    // remove from DOM when completed
    if( this.el && this.el.parentNode ) this.el.parentNode.removeChild(this.el);

    // destroy button component
    if( this.btn ) this.btn.destroy();
    if( this._audioBtn ) this._audioBtn.destroy();
    
    // pause & destroy videos
    if( this.bgVideo ) {
      this.bgVideo.pause();
      this.bgVideo.destroy();
    }

    if( this.introVideo ) {
      this.introVideo.pause();
      this.introVideo.destroy();
    }

    this.el = null;
    this.bg = null;
    this.intro = null;
    this.bgVideo = null;
    this.introVideo = null;
    this.form = null;
    this.btn = null;
    this.day = null;
    this.month = null;
    this.year = null;
    this.audioBtn = null;
    this.sorry = null;

    this._ageGate = null;
    this._ageIsValid = null;
    this._readyPromise = null;
    this._tick = null;
    this._timecode = null;

    this._onSubmit = null;
    this._onAudioBtnClick = null;
    this._onSFXChange = null;
    this._onAnimateOutCompleted = null;
    this._onGoToIntroCompleted = null;
    this._onIntroTimecode = null;
    this._onIntroEnded = null;
  }

  _animateIn() {
    this.el.classList.add(ANIMATE_IN_CLASSNAME);

    if( this.bgVideo ) this.bgVideo.play();
  }
  _animateOut() {
    html.classList.remove(AGE_GATE_CLASSNAME);
    html.classList.add("--js-ready");
    if( this._ageGate ) ResizeOrientation.trigger();
    
    on(this.el, 'transitionend', this._onAnimateOutCompleted);
    this.el.classList.add(ANIMATE_OUT_CLASSNAME);

    this._readyPromise();
    this._readyPromise = null;
  }
  _goToIntro() {
    if( !this.intro || PowerMode.low ) {
      this._animateOut();
    } else {
      on(mobile ? this.form : this.audioBtn, 'animationend', this._onGoToIntroCompleted);
      this.el.classList.add(INTRO_CLASSNAME);
    }
  }

  _onSubmit(event) {
    if( event ) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }

    // if ageGate is already passed, stop here
    if( this._ageIsValid ) return false;

    // if native form validation is not supported
    if( this.form.reportValidity ){
      // if form validation fails, skip here
      if( !this.form.reportValidity() ) return false;
    }

    const year = Math.min(new Date().getFullYear(), parseInt(this.year.value));
    const month = Math.min(11, parseInt(this.month.value) - 1);
    const day = Math.min(31, parseInt(this.day.value));
    const birthDate = new Date(year, month, day);
    const age = this._getAgeFromBirthdate(birthDate);

    if( age >= REQUIRED_AGE ) {
      this._ageIsValid = true;

      // disable form
      this.form.classList.add('pointer-events-none');

      // unlock age gate for next time
      AgeGate.unlock();

      // if site is ready for action
      if( this._readyPromise ) this._goToIntro();
    } else {
      this.sorry.setAttribute('aria-hidden', false);
    }

    return false;
  }
  _onAudioBtnClick() { SFX.toggle(); }
  _onSFXChange() {
    this.audioBtn.classList[SFX.muted ? 'add' : 'remove']('--muted');
    this.audioBtn.setAttribute('aria-label', this.audioBtn.dataset[SFX.muted ? 'ariaOff' : 'ariaOn']);

    if( this.introVideo && !mobile ) this.introVideo.el.muted = SFX.muted; 
  }
  _onAnimateOutCompleted(event) {
    if( event.target !== this.el ) return;
    this._destroy();
  }
  _onGoToIntroCompleted() {
    off(this.form, 'animationend', this._onGoToIntroCompleted);
    off(this.audioBtn, 'animationend', this._onGoToIntroCompleted);

    if( this.intro ) this.intro.classList.remove('visibility-hidden');
    if( this.introVideo ) {
      if( this._timecode ) {
        this._timecode = this._convertTimecodeToMS(this._timecode);
        this._tick = requestInterval(this._onIntroTimecode, 1000 / 30);
      }
      else on(this.introVideo.el, 'ended', this._onIntroTimecode);
      
      on(this.introVideo.el, 'ended', this._onIntroEnded);
      this.introVideo.play();
    }

    if( this.bgVideo ) this.bgVideo.pause();
    if( this.bg ) this.bg.classList.add('visibility-hidden');
  }
  _onIntroTimecode() {
    // if intro video hasn't reached timecode, wait
    if( this._timecode && this.introVideo.el.currentTime < this._timecode ) return;

    // timecode has been reached, stop interval
    if( this._tick ) this._tick();
    this._tick = null;

    this.el.setAttribute('aria-hidden', true);
    html.classList.remove(AGE_GATE_CLASSNAME);
    html.classList.add("--js-ready");
    ResizeOrientation.trigger();

    this._readyPromise();
    this._readyPromise = null;
  }
  _onIntroEnded() {
    // if video ended without reaching timecode, run timecode event before
    if( this._readyPromise ) {
      this._timecode = null;
      this._onIntroTimecode();
    }

    this._destroy();
  }

  // https://stackoverflow.com/questions/10008050/get-age-from-birthdate
  _getAgeFromBirthdate(birthDate) {
    const today = new Date();
    const m = today.getMonth() - birthDate.getMonth();
    let age = today.getFullYear() - birthDate.getFullYear();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age--;
    return age;
  }
  _convertTimecodeToMS(timecode) {
    // if we can't find :, stop here
    if( timecode.indexOf(":") < 0 ) return null;

    // split timecode into two parts
    let [seconds, frames] = timecode.split(':');

    // set default values
    if( !seconds ) seconds = 0;
    if( !frames ) frames = 0;

    // transform 00 to 0
    seconds = parseInt(seconds);
    frames = parseInt(frames);

    // if timecode is 00:00, stop here
    if( seconds === 0 && frames === 0 ) return null;

    // convert frames to seconds
    frames = frames / 30;
    
    // return as seconds
    return (seconds + frames);
  }
}

export default SiteLoader;
