import SiteLoader from "./SiteLoader";
import SiteTransition from "./SiteTransition";
//import SiteAsyncTransition from "./SiteAsyncTransition";
import GameHeaderTransition from "./GameHeaderTransition";
import VideoTransition from "./VideoTransition";

export default [
  new SiteLoader(), 
  new SiteTransition(),
  //new SiteAsyncTransition(),
  new GameHeaderTransition(),
  new VideoTransition(),
];
